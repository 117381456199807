import { HTMLAttributes } from 'react';
import { Icon } from "@iconify/react";

export default function InputError({ message, className = '', ...props }: HTMLAttributes<HTMLParagraphElement> & { message?: string }) {
    return message ? (
        <p {...props} className={'text-sm block gap-2 text-red-600 bg-red-300 px-3 py-1 rounded-sm ' + className}>
            <Icon icon={'mdi:warning-octagon-outline'} className="inline align-middle mr-2" />
            <div className="inline align-middle">
                {message}
            </div>
        </p>
    ) : null;
}
